<template>
  <div>
    <book-list :set="false" :datalist="bookData"></book-list>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :page-size.sync="pageCount"
      :current-page="currentPage"
      :total="total"
      v-if="total>pageCount"
    ></el-pagination>
  </div>
</template>
<script>
import BookList from "../../book/components/Booklist.vue";
import { booksBoughtRequest } from "@/api/project.js";
export default {
  components: {
    BookList
  },
  data() {
    return {
      bookData: "",
      currentPage:1,
      total:2,
      pageCount:10
    };
  },
  created() {
    this.getBook();
  },
  methods: {
    async getBook(val) {
      try {
        val=val || '1'
        const result = await booksBoughtRequest({
          page: val
        });
        if (result.status == 200) {
          this.bookData = result.data.list;
          this.total=result.data.totalCount
          this.pageCount=result.data.pageCount
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getBook(val);
    }
  }
};
</script>
<style  lang="less" scoped>
.el-pagination{
text-align: right;
margin:20px;
}
/deep/.booklist{
    margin:0 -20px;
      & > li {
   width: 20%;
}
}
</style>